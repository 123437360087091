<template />

<script>
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
// import { datadogRum } from '@datadog/browser-rum'

export default {
  name: 'Logout',
  created() {
    this.logout()
  },
  methods: {
    logout() {
      console.log('ingreso a logout')
      // Remove userData from localStorage
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // datadogRum.stopSessionReplayRecording()

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}

</script>

<style scoped>

</style>
